<template>
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 65 65"
    >
      <g transform="translate(-271 -470)">
        <g class="a" :class="{ active: active }" transform="translate(271 470)">
          <circle class="c" cx="32.5" cy="32.5" r="32.5" />
          <circle class="d" cx="32.5" cy="32.5" r="31.5" />
        </g>
        <g transform="translate(77 20)">
          <line
            class="b"
            :class="{ active: active }"
            x2="16"
            y2="16"
            transform="translate(218.5 474.5)"
          />
          <line
            class="b"
            :class="{ active: active }"
            x1="16"
            y2="16"
            transform="translate(218.5 474.5)"
          />
        </g>
      </g>
    </svg>
    <p>DNF</p>
  </span>
</template>

<script>
export default {
  name: "KeyboardDnf",
  props: ["active"],
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    colors() {
      return this.$store.state.colors;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
  p {
    font-size: 10px;
    margin: 0;
    margin-top: 7px;
    color: $watermelon;
    font-family: "Gilroy-Bold", "sans-serif";
    font-weight: normal;
    font-style: normal;
  }
}

.a {
  transition: all ease 0.3s;
  &.active {
    fill: $watermelon;
  }
}

.b {
  transition: all ease 0.3s;
  &.active {
    stroke: $midnight;
  }
}

.a,
.b,
.d {
  fill: none;
}
.a,
.b {
  stroke: #ff326e;
}
.a {
  stroke-width: 2px;
}
.b {
  stroke-width: 3px;
}
.c {
  stroke: none;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
