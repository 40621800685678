<template>
  <div class="signature-container">
    <div class="banner">
      <div class="finished" v-if="allPlayersSigned">
        All results have been signed
      </div>
      <div class="unfinished" v-else>Sign your result by entering your PIN</div>
    </div>
    <div class="players-container">
      <div class="player" v-for="player in players" :key="player.id">
        <div class="info">
          <div class="left">
            <span class="first">
              {{ player.player.user.firstName }}
              <img
                src="@/assets/img/PIN-badge-confirmed.svg"
                alt
                v-if="player.hasSignedResult"
              />
            </span>
            <span class="last">{{ player.player.user.lastName }}</span>
          </div>
          <div
            class="right"
            v-if="!showKeyboard || tappedPlayer != player.player.id"
          >
            <div class="total-score">{{ playerResult(player) }}</div>
            <div class="sign-status">
              <span class="finished" v-if="player.hasSignedResult">Signed</span>
              <span
                class="unfinished"
                @click="openKeyboard(player.player.id, $event)"
                v-else
                >Tap to sign</span
              >
            </div>
          </div>
          <div class="right" v-else>
            <div class="total-score big">{{ playerResult(player) }}</div>
          </div>
        </div>
        <div
          class="keyboard"
          v-if="showKeyboard && tappedPlayer == player.player.id"
        >
          <div class="pin-area">
            <div
              class="pin-digit"
              v-for="i in enteredPin.length"
              :key="player.player.id + i"
            >
              <img src="@/assets/img/asterisk-mid.svg" alt />
              <img src="@/assets/img/PIN-entry-idle-light.svg" alt />
            </div>
            <div class="pin-digit" v-if="!pinCorrectLength">
              <img src="@/assets/img/PIN-entry-active-light.svg" alt />
            </div>
            <div
              class="pin-digit"
              v-for="i in digitsLeft"
              :key="'idle' + player.player.id + i"
            >
              <img src="@/assets/img/PIN-entry-idle-light.svg" alt />
            </div>
          </div>
          <div class="messages">
            <div class="incorrect" v-if="showWrongPinMessage">
              Incorrect PIN
            </div>
            <div class="already-signed" v-if="showAlreadySignedMessage">
              This result was already signed. Good job checking twice ;)
            </div>
            <div class="locked" v-if="showLockedMessage">
              Your PIN was entered 5 times incorrectly and is now locked. Update
              your PIN on your profile page.
            </div>
            <div class="incorrect" v-if="showUnverifiedScoresMessage">
              You cannot sign results until all scores are verified!
            </div>
            <div class="incorrect" v-if="showNoPinAddedMessage">
              No PIN added, go to your profile and add one!
            </div>
          </div>
          <div class="numpad">
            <div class="digit" @click="tappedKey(i)" v-for="i in 9" :key="i">
              {{ i }}
            </div>
            <div class="digit">
              <img
                @click="removeDigit()"
                src="@/assets/img/delete-white-outline.svg"
                alt
              />
            </div>
            <div class="digit" @click="tappedKey(0)">0</div>
            <div class="digit empty">
              <img
                v-if="pinCorrectLength"
                src="@/assets/img/confirm-score.svg"
                alt
                @click="submit()"
              />
            </div>
            <div class="close-numpad" @click="closeKeyboard()">Close</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
var scrollToOptions = {
  offset: -69,
  duration: 750,
};

export default {
  name: "TheDigitalSignature",
  props: ["players", "results"],
  data() {
    return {
      showKeyboard: false,
      tappedPlayer: "",
      showLockedMessage: false,
      showAlreadySignedMessage: false,
      showWrongPinMessage: false,
      showUnverifiedScoresMessage: false,
      showNoPinAddedMessage: false,
      enteredPin: "",
    };
  },
  computed: {
    allPlayersSigned() {
      let allSigned = true;
      for (var i = 0; i < this.players.length; i++) {
        if (!this.players[i].hasSignedResult) {
          allSigned = false;
        }
      }

      return allSigned;
    },
    digitsLeft() {
      if (3 - this.enteredPin.length > 0) {
        return 3 - this.enteredPin.length;
      } else return 0;
    },
    pinCorrectLength() {
      if (this.enteredPin.length == 4) {
        return true;
      }
      return false;
    },
  },
  watch: {},
  methods: {
    submit() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            SignResult(groupId:"${this.$router.currentRoute.params.groupId}",playerId:"${this.tappedPlayer}",pinCode:"${this.enteredPin}",asRole:USER){
              id
            }
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.SignResult != null) {
            this.$parent.loadData();
            this.closeKeyboard();
          } else {
            if (
              result.data.errors[0].errorCode == "GROUP_HAS_UNVERIFIED_RESULTS"
            ) {
              this.showUnverifiedScoresMessage = true;
            } else if (result.data.errors[0].errorCode == "PIN_CODE_LOCKED") {
              this.showLockedMessage = true;
            } else if (
              result.data.errors[0].errorCode == "WRONG_PIN_CODE_ENTERED"
            ) {
              this.showWrongPinMessage = true;
            } else if (result.data.errors[0].errorCode == "NO_PIN_CODE") {
              this.showNoPinAddedMessage = true;
            } else if (result.data.errors[0].errorCode == "PIN_CODE_LOCKED") {
              this.showLockedMessage = true;
            }
          }
        })
        .catch(() => {
          this.$emit("connectionError");
        });
    },
    playerResult(player) {
      let playerResults = this.results.filter(
        (result) => result.playerConnection.id == player.id
      );
      let playerTotal = 0;

      for (var i = 0; i < playerResults.length; i++) {
        playerTotal += playerResults[i].score;
      }

      return playerTotal;
    },
    closeKeyboard() {
      this.tappedPlayer = "";
      this.showKeyboard = false;
    },
    openKeyboard(player, event) {
      this.tappedPlayer = player;
      this.showKeyboard = true;
      this.showLockedMessage = false;
      this.showAlreadySignedMessage = false;
      this.showWrongPinMessage = false;
      this.showNoPinAddedMessage = false;
      this.showUnverifiedScoresMessage = false;
      this.enteredPin = "";

      VueScrollTo.scrollTo(event.srcElement, scrollToOptions);
    },
    removeDigit() {
      this.enteredPin = this.enteredPin.slice(0, -1);
      this.showLockedMessage = false;
      this.showAlreadySignedMessage = false;
      this.showWrongPinMessage = false;
      this.showNoPinAddedMessage = false;
      this.showUnverifiedScoresMessage = false;
    },
    tappedKey(i) {
      if (this.enteredPin.length < 4) {
        this.enteredPin += i;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.signature-container {
  background: white;
}
.keyboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  .pin-area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 60px;
    margin-top: 10px;

    img {
      margin: 0 20px;
    }

    .pin-digit {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
  .messages {
    text-align: center;
    @include Gilroy-Bold;
    font-size: 14px;
    height: 30px;
    padding: 0 12px;
    span {
      @include Gilroy-Medium;
    }

    .incorrect {
      color: $watermelon;
      margin: 10px 0px 30px 0px;
    }
  }
  .numpad {
    background-color: $midnight;
    display: flex;
    flex-wrap: wrap;
    align-items: space-around;
    justify-content: center;
    margin-top: 30px;
    padding-top: 15px;

    .digit {
      margin: 5px 0px;
      width: 33.3%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      @include Gilroy-Bold;
      color: white;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }

    .close-numpad {
      height: 50px;
      color: $elephant;
      @include Gilroy-Bold;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.banner {
  .unfinished {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: $sunrise;
    @include Gilroy-Bold;
    font-size: 18px;
  }
  .finished {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background-color: $avocado;
    @include Gilroy-Bold;
    font-size: 18px;
    color: white;
  }
}

.players-container {
  .player {
    border-bottom: 1px solid $elephant;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .info {
      padding: 15px 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .left {
        display: flex;
        flex-direction: column;

        .first {
          font-size: 36px;
          @include Gilroy-Bold;
          text-transform: uppercase;
          display: flex;
          align-items: center;

          img {
            margin-left: 10px;
          }
        }
        .last {
          font-size: 20px;
          @include Gilroy-Medium;
          text-transform: uppercase;
        }
      }
      .right {
        display: flex;
        flex-direction: column;

        .total-score {
          text-align: right;
          color: $elephant;
          font-size: 36px;
          @include Gilroy-Bold;

          &.big {
            font-size: 55px;
          }
        }

        .unfinished {
          background-color: $watermelon;
          padding: 2px 5px 4px 5px;
          border-radius: 3px;
          color: white;
          @include Gilroy-Bold;
        }
        .finished {
          @include Gilroy-Regular;
          color: $elephant;
        }
      }
    }
  }

  .messages {
    font-size: 12px;
    @include Gilroy-Bold;

    .already-signed {
      color: $albatross;
      margin: 15px 0;
    }
    .locked {
      color: $watermelon;
      margin: 15px 0;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
</style>
