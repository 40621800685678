<template>
  <div class="scorecard-keyboard">
    <!-- Standard Keyboard -->
    <transition name="fade" mode="out-in">
      <div class="score-stats-container" v-if="shownHorizontalPosition == 1">
        <div class="scores">
          <div
            class="lower"
            @click="moveScores({ direction: 4 })"
            :class="{ selectable: availableScores[0] != 1 }"
          >
            <img src="@/assets/img/lower-scores.svg" alt />
          </div>
          <div class="scores-inner-container" v-hammer:swipe="moveScores">
            <div
              class="score noselect"
              @click="selectedScoreManager(n)"
              v-for="n in availableScores"
              :key="n"
              :class="{ selected: selectedScore == n }"
            >
              {{ n }}
            </div>
          </div>
          <div class="higher" @click="moveScores({ direction: 2 })">
            <img src="@/assets/img/higher-scores.svg" alt />
          </div>
        </div>
        <div
          class="stats"
          v-hammer:swipe="moveStats"
          v-show="shownStatsPosition == 1"
        >
          <div
            class="lower"
            @click="shownStatsPosition = 0"
            :class="{ selectable: shownStatsPosition > 0 }"
          >
            <img src="@/assets/img/lower-scores.svg" alt />
          </div>
          <div class="stats-inner-container noselect">
            <img
              src="@/assets/img/circle-hit.svg"
              :class="{ selected: selectedStats.isCircleHit }"
              @click="statsManager('isCircleHit')"
            />
            <img
              src="@/assets/img/inside-putt.svg"
              :class="{ selected: selectedStats.isInsidePutt }"
              @click="statsManager('isInsidePutt')"
            />
            <img
              src="@/assets/img/outside-putt.svg"
              :class="{ selected: selectedStats.isOutsidePutt }"
              @click="statsManager('isOutsidePutt')"
            />
            <img
              src="@/assets/img/ob.svg"
              :class="{ selected: selectedStats.isOutOfBounds }"
              @click="statsManager('isOutOfBounds')"
            />
          </div>
          <div class="higher" @click="moveStats({ direction: 2 })"></div>
        </div>
        <div
          class="control-container"
          v-show="shownStatsPosition == 0"
          v-hammer:swipe="moveStats"
        >
          <div class="lower"></div>
          <div class="control-inner-container">
            <div></div>
            <div></div>
            <div></div>
            <KeyboardDnf :active="dnfSelected" v-hammer:tap="toggleDNF" />
          </div>
          <div class="higher" @click="shownStatsPosition = 1">
            <img src="@/assets/img/higher-scores.svg" alt />
          </div>
        </div>
        <div class="verify noselect">
          <img
            src="@/assets/img/confirm-score.svg"
            :class="{
              selectable:
                selectedScore != 0 || (dnfSelected && shownStatsPosition == 0),
            }"
            @click="submit()"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import KeyboardDnf from "@/assets/icons/KeyboardDnf";

export default {
  name: "TheSinglePlayerKeyboard",
  props: ["preset", "player", "hole", "result", "marshall"],
  components: {
    KeyboardDnf,
  },
  data() {
    return {
      scoreScrollPosition: 0,
      currentPar: 3,
      shownScoresModifier: 0,
      shownHorizontalPosition: 1,
      shownStatsPosition: 1,
      selectedScore: 0,
      scorePan: false,
      dnfSelected: false,
      selectedStats: {
        isCircleHit: false,
        isInsidePutt: false,
        isOutsidePutt: false,
        isOutOfBounds: false,
      },
    };
  },
  computed: {
    asRole() {
      var role = "";
      if (this.marshall) {
        role = "GROUP_MARSHALL";
      } else {
        role = "USER";
      }
      return role;
    },
    availableScores() {
      // if we want the shown available scores to be different based on par change the 3 to this.currentPar
      let scores = [];
      let currentPar = 3 + this.shownScoresModifier;
      scores.push(currentPar - 1);
      scores.push(currentPar);
      scores.push(currentPar + 1);
      scores.push(currentPar + 2);

      return scores;
    },
  },
  watch: {
    preset: {
      handler: function (newValue) {
        this.selectedScore = newValue;
        this.selectedStats.isCircleHit = newValue.isCircleHit;
        this.selectedStats.isInsidePutt = newValue.isInsidePutt;
        this.selectedStats.isOutsidePutt = newValue.isOutsidePutt;
        this.selectedStats.isOutOfBounds = newValue.isOutOfBounds;
        this.currentPar = this.hole.par;
      },
      deep: true,
    },
  },
  methods: {
    toggleDNF() {
      this.selectedScore = 0;
      this.dnfSelected = !this.dnfSelected;
      this.selectedStats.isCircleHit = false;
      this.selectedStats.isInsidePutt = false;
      this.selectedStats.isOutsidePutt = false;
      this.selectedStats.isOutOfBounds = false;
    },
    submit() {
      this.$store.dispatch("authCheck");

      if (this.dnfSelected && this.shownStatsPosition == 0) {
        this.$emit("dnfPlayer", this.player.player);
      } else if (this.selectedScore != 0) {
        if (Object.keys(this.result).length == 0) {
          this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation CreateResult($CreateResult: ResultInput!){
                CreateResult(groupId:"${this.$router.currentRoute.params.groupId}",holeId:"${this.hole.id}",playerId:"${this.player.player.id}",asRole:${this.asRole},input:$CreateResult){
                  id
                  score
                  isCircleHit
                  isOutsidePutt
                  isInsidePutt
                  isOutOfBounds
                  playerConnection{
                    id
                  }
                  hole{
                    id
                    par
                  }
                }

              }
              `,
              variables: {
                CreateResult: {
                  score: this.selectedScore,
                  isCircleHit: this.selectedStats.isCircleHit,
                  isOutsidePutt: this.selectedStats.isOutsidePutt,
                  isInsidePutt: this.selectedStats.isInsidePutt,
                  isOutOfBounds: this.selectedStats.isOutOfBounds,
                },
              },
            },
          })
            .then((result) => {
              if (result.data.data.CreateResult == null) {
                if (
                  result.data.errors[0].errorCode ==
                  "GROUP_HAS_UNVERIFIED_RESULTS"
                ) {
                  this.$emit("unverified");
                } else if (
                  result.data.errors[0].errorCode == "RESULT_FOR_HOLE_EXISTS"
                ) {
                  this.$emit("duplicate");
                } else if (
                  result.data.errors[0].errorCode == "GROUP_NOT_OPEN"
                ) {
                  this.$emit("groupclosed");
                } else if (
                  result.data.errors[0].errorCode == "RESULT_IS_SIGNED"
                ) {
                  this.$emit("alreadysigned");
                }
              } else {
                this.$emit("newresult", result.data.data);
              }
            })
            .catch(() => {
              this.$emit("connectionError");
            });
        } else {
          this.$store.dispatch("authCheck");

          this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation UpdateResult($UpdateResult: UpdateResultInput!){
                UpdateResult(resultId:"${this.result.id}",asRole:${this.asRole},input:$UpdateResult){
                  id
                  score
                  isCircleHit
                  isOutsidePutt
                  isInsidePutt
                  isOutOfBounds
                  playerConnection{
                    id
                  }
                  hole{
                    id
                    par
                  }
                }
              }
              `,
              variables: {
                UpdateResult: {
                  score: this.selectedScore,
                  isCircleHit: this.selectedStats.isCircleHit,
                  isOutsidePutt: this.selectedStats.isOutsidePutt,
                  isInsidePutt: this.selectedStats.isInsidePutt,
                  isOutOfBounds: this.selectedStats.isOutOfBounds,
                },
              },
            },
          })
            .then((result) => {
              this.$emit("updateResult", result.data.data);
            })
            .catch(() => {
              this.$emit("connectionError");
            });
        }
      }
    },
    selectedScoreManager(n) {
      if (this.dnfSelected) {
        this.dnfSelected = false;
      }
      if (this.selectedScore == n) {
        this.selectedScore = 0;
        this.selectedStats.isCircleHit = false;
        this.selectedStats.isInsidePutt = false;
        this.selectedStats.isOutsidePutt = false;
        this.selectedStats.isOutOfBounds = false;
      } else {
        this.selectedScore = n;
        this.selectedStats.isCircleHit = false;
        this.selectedStats.isInsidePutt = false;
        this.selectedStats.isOutsidePutt = false;
        this.selectedStats.isOutOfBounds = false;

        if (n == 1) {
          this.selectedStats.isCircleHit = true;
        }
      }
    },
    statsManager(stat) {
      if (this.selectedScore != 0) {
        if (stat == "isCircleHit") {
          if (this.selectedScore == 1) {
            this.selectedStats.isCircleHit = true;
            this.selectedStats.isInsidePutt = false;
          } else if (this.selectedScore <= this.currentPar - 1) {
            if (this.selectedStats.isCircleHit == true) {
              this.selectedStats.isCircleHit = false;
              this.selectedStats.isInsidePutt = false;
            } else {
              this.selectedStats.isCircleHit = true;
              this.selectedStats.isInsidePutt = true;

              this.selectedStats.isOutsidePutt = false;
            }
          } else {
            if (this.selectedStats.isCircleHit == true) {
              this.selectedStats.isCircleHit = false;
            } else {
              this.selectedStats.isCircleHit = true;

              if (this.selectedScore >= this.currentPar) {
                this.selectedStats.isInsidePutt = false;
              }
            }
          }
        }
        if (stat == "isInsidePutt") {
          if (this.selectedScore == 1) {
            this.selectedStats.isCircleHit = true;
            this.selectedStats.isInsidePutt = false;
          } else if (this.selectedStats.isInsidePutt == true) {
            if (this.selectedScore <= this.currentPar - 1) {
              this.selectedStats.isCircleHit = false;
            }

            this.selectedStats.isInsidePutt = false;
          } else {
            this.selectedStats.isOutsidePutt = false;

            if (this.selectedScore <= this.currentPar - 1) {
              this.selectedStats.isCircleHit = true;
            } else {
              this.selectedStats.isCircleHit = false;
            }
            this.selectedStats.isInsidePutt = true;
          }
        }
        if (stat == "isOutsidePutt") {
          this.selectedStats.isInsidePutt = false;

          if (this.selectedScore == 1) {
            this.selectedStats.isOutsidePutt = false;
          } else if (this.selectedStats.isOutsidePutt == true) {
            this.selectedStats.isOutsidePutt = false;
          } else {
            this.selectedStats.isOutsidePutt = true;
          }
        }
        if (stat == "isOutOfBounds") {
          if (this.selectedScore > 2) {
            if (this.selectedStats.isOutOfBounds == true) {
              this.selectedStats.isOutOfBounds = false;
            } else {
              this.selectedStats.isOutOfBounds = true;
            }
          }
        }
      }
    },
    moveStats(event) {
      if (event.direction == 4) {
        this.shownStatsPosition = 0;
        this.selectedScore = 0;
      }
      if (event.direction == 2) {
        this.shownStatsPosition = 1;
      }
    },
    scorePress(event) {
      if (event.isFirst) {
        this.scorePan = true;
      }
      if (event.isFinal) {
        this.scorePan = false;
      }
    },
    moveScores(event) {
      if (event.direction == 2) {
        this.shownScoresModifier += 2;
      } else if (event.direction == 4) {
        if (this.availableScores[0] == 2) {
          this.shownScoresModifier--;
        }
        if (this.availableScores[0] > 1) {
          this.shownScoresModifier -= 2;
        }
      } else if (event.direction == 8) {
        if (this.shownHorizontalPosition == 2) this.shownHorizontalPosition--;
        else this.shownHorizontalPosition++;
      } else if (event.direction == 16) {
        if (this.shownHorizontalPosition == 0) this.shownHorizontalPosition++;
        else this.shownHorizontalPosition--;
      }
    },
  },
  beforeMount() {
    this.selectedScore = this.preset.score;
    this.selectedStats.isCircleHit = this.preset.isCircleHit;
    this.selectedStats.isInsidePutt = this.preset.isInsidePutt;
    this.selectedStats.isOutsidePutt = this.preset.isOutsidePutt;
    this.selectedStats.isOutOfBounds = this.preset.isOutOfBounds;
    // Set this if we want the keyboard load the starting numbers shown dynamically
    this.currentPar = this.hole.par;
    // this.currentPar = 3
  },
};
</script>
<style lang="scss" scoped>
.scorecard-keyboard {
  height: 300px;
  max-height: 300px;
  display: flex;
  background-color: $midnight;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  transition: all ease 0.3s;
  -webkit-user-select: none !important;

  .score-stats-container {
    display: block;
    height: 200px;
    width: 100%;
    transition: all ease 0.5s;
    padding: 15px 5px;

    .higher,
    .lower {
      opacity: 0.3;
      width: 25px;
    }

    .lower {
      img {
        opacity: 0;
        transition: all ease 0.3s;
      }
      &.selectable img {
        opacity: 1;
      }
    }

    .scores {
      height: 80px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
    }

    .stats {
      height: 100px;
      display: flex;
      width: 100%;
      padding: 0px;
      align-items: center;
      justify-content: space-between;

      .stats-inner-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .selectable img {
        opacity: 1 !important;
      }

      img {
        width: 60px;
        opacity: 0.4;
        transition: all ease 0.3s;

        &.selected {
          opacity: 1;
        }
      }

      .higher {
        min-width: 25px;

        img {
          opacity: 1;
          width: 25px;
          margin-bottom: 12px;
        }
      }
      .lower img {
        opacity: 0;
        width: 25px;
        margin-bottom: 12px;

        &.active {
          opacity: 1;
        }
      }
    }
    .verify {
      height: 100px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      cursor: pointer;

      img {
        transition: all ease 0.3s;

        width: 60px;
        opacity: 0.3;

        &.selectable {
          opacity: 1;
        }
      }
    }
    .scores-inner-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
    }

    .score {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid white;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      @include Gilroy-Bold;
      color: white;
      margin: 25px 0px;
      font-size: 32px;
      line-height: 60px;
      transition: all ease 0.5s;
      opacity: 0.5;

      &.noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
      }
      &.selected {
        background-color: white;
        color: $midnight;
        opacity: 1;
      }
    }
  }

  .control-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    height: 100px;
    width: 100%;

    .control-inner-container {
      display: flex;
      justify-content: space-around;
      height: 100%;
      width: 100%;

      img {
        width: 60px;
      }
    }
    span {
      width: 60px;
    }

    div {
      width: 60px;
      height: 60px;
    }

    .lower {
      width: 25px;
      height: 10px;
      display: flex;
    }
    .higher {
      height: auto;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
  .score-stats-container {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (min-width: 544px) {
  .score-stats-container {
    max-width: 400px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .scorecard-keyboard {
    border-top: 1px solid $strom;
  }
}
</style>
