<template>
  <div
    class="scorecard-container"
    v-if="!loading"
    :class="{ 'bottom-spacing': showKeyboard }"
  >
    <TheDigitalSignature
      v-if="showDigitalSignature"
      :players="groupData.playerConnections"
      :results="groupData.results"
    />
    <div
      class="scorecard-header"
      :class="{ fixed: !blockScorecardHeader, hidden: !showScorecardHeader }"
    >
      <div class="left-column">
        <div class="division-spacer"></div>
        <div class="menu-container" @click="toggleMenu">
          <div id="nav-icon3" class="d-lg-none" :class="{ open: showMenu }">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="players-container">
        <div
          class="player-column"
          :style="{ width: 100 / groupData.playerConnections.length + '%' }"
          v-for="player in groupData.playerConnections"
          :key="player.id"
        >
          <div class="division"></div>
          <div
            class="player"
            v-hammer:press="(event) => toggleDNFModal(event, player)"
          >
            <span class="first-name">{{ player.player.user.firstName }}</span>
            <span class="last-name">{{
              formattedLastName(player.player.user.lastName)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="scorecard-body"
      :class="{ 'margin-top': !blockScorecardHeader }"
    >
      <div class="left-column">
        <div
          class="hole-number"
          :class="{ small: showHoleNameOrNumber(hole).length > 2 }"
          @click="toggleLock(hole)"
          v-for="hole in holeOrder"
          :key="hole.number"
        >
          {{ showHoleNameOrNumber(hole) }}
          <transition name="fade" mode="out-in">
            <img
              src="@/assets/img/hole-indicator.svg"
              class="indicator"
              v-if="hole.id == currentHole.id"
            />
          </transition>
          <img
            v-if="lockedHole(hole) && !isUnlocked(hole)"
            class="locked"
            src="@/assets/img/score-locked.svg"
          />
          <img
            v-if="isUnlocked(hole)"
            class="locked"
            src="@/assets/img/score-unlocked.svg"
          />
        </div>
      </div>
      <div class="players-container">
        <div
          class="player-column"
          :style="{ width: 100 / groupData.playerConnections.length + '%' }"
          v-for="(player, playerIndex) in groupData.playerConnections"
          :key="playerIndex"
        >
          <div
            class="player-result"
            :key="hole.id + player.id"
            v-for="hole in holeOrder"
            @click="loadKeyboard(hole, player, $event)"
            :style="resultParColor(hole, resultToDisplay(player, hole).score)"
            :class="{ clicked: clickedScore == hole.id + player.id }"
          >
            {{ resultToDisplay(player, hole).score }}
            <template v-if="isUnlocked(hole) || showAllStats">
              <img
                v-if="resultToDisplay(player, hole).isCircleHit"
                class="circlehit"
                src="@/assets/img/overlay_circle_hit.svg"
              />
              <img
                v-if="resultToDisplay(player, hole).isInsidePutt"
                class="inside-putt"
                src="@/assets/img/overlay_inside_putt.svg"
              />
              <img
                v-if="resultToDisplay(player, hole).isOutsidePutt"
                class="outside-putt"
                src="@/assets/img/overlay_outside_putt.svg"
              />
              <img
                v-if="resultToDisplay(player, hole).isOutOfBounds"
                class="ob"
                src="@/assets/img/overlay_out_of_bounds.svg"
              />
            </template>

            <p
              class="teeorder"
              v-if="hole.id == currentHole.id && showTeeOrder"
            >
              {{ playersTeeOrder(player) }}
            </p>
            <transition name="slide-from-right" mode="out-in">
              <div
                @click.stop="toggleLock(hole)"
                class="unlock-question"
                v-if="
                  unlockQuestion == hole.id &&
                  playerIndex == groupData.playerConnections.length - 1 &&
                  groupData.status != 'DONE'
                "
              >
                Tap to unlock
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="scorecard-footer">
      <div class="toggle-area" @click="toggleTotals()">
        <img v-if="showTotalPar == true" src="@/assets/img/hide-white.svg" />
        <img v-else src="@/assets/img/show-white.svg" />
      </div>
      <div
        class="player-result"
        v-for="player in groupData.playerConnections"
        :key="player.id"
        :style="{ width: 100 / groupData.playerConnections.length + '%' }"
      >
        <div v-if="showTotalScore" class="score">
          {{ getPlayerTotals(player).playerTotal }}
        </div>
        <div v-if="showTotalPar" class="par">
          {{ showParPrefix(getPlayerTotals(player).playerPar) }}
        </div>
        <div v-if="!showTotalScore && !showTotalPar" class="hidden">
          <img src="@/assets/img/hide-white.svg" />
        </div>
      </div>
    </div>
    <div class="submit-area" v-if="scoreCardCompleted">
      <div
        class="submit-scores"
        v-if="
          groupData.status == 'OPEN' &&
          !groupData.pool.round.event.isResultSigningEnabled
        "
        @click="modalControl.showSubmitWarningModal = true"
      >
        <div>
          <img src="@/assets/img/submit-white.svg" alt />
          Submit scores
        </div>
      </div>
      <div class="scores-submitted" v-if="groupData.status == 'DONE'">
        <div>
          <img src="@/assets/img/checked.svg" alt />
          Submitted
        </div>
      </div>
    </div>

    <transition name="slide-up" mode="out-in">
      <TheSinglePlayerKeyboard
        :preset="keyBoardPreset"
        :hole="keyBoardHole"
        :player="keyBoardPlayer"
        :result="keyBoardResult"
        :marshall="isUserMarshall"
        @closeKeyboard="showKeyboard = false"
        @newresult="createResult"
        @updateResult="updateResult"
        @connectionError="connectionError()"
        @unverified="unverifiedResult"
        @duplicate="duplicateResult"
        @groupclosed="groupclosed"
        @alreadysigned="alreadysigned"
        @dnfPlayer="toggleDNFModal"
        v-if="showKeyboard"
      />
    </transition>

    <transition name="slide-in-left" mode="out-in">
      <TheScorecardMenu
        v-if="showMenu"
        @closeMenu="toggleMenu"
        @resignMarshall="resignMarshall"
        :group="groupData"
        :lockedHeader="blockScorecardHeader"
        @refresh="refreshScorecard()"
      />
    </transition>

    <transition name="fade" mode="out-in">
      <div
        class="dim"
        v-if="showMenu"
        :class="{ locked: blockScorecardHeader }"
      ></div>
    </transition>

    <transition name="fade" mode="out-in">
      <TheScorecardModal
        v-if="showAModal"
        :modals="modalControl"
        :player="dnfPlayer"
        @dnfPlayer="actionDNFPlayer()"
        @refresh="refreshScorecard()"
        @submitScores="lockScorecard()"
      />
    </transition>
  </div>
</template>

<script>
import TheSinglePlayerKeyboard from "@/components/scorecard/TheSinglePlayerKeyboard";
import TheScorecardMenu from "@/components/scorecard/TheScorecardMenu";
import TheScorecardModal from "@/components/scorecard/TheScorecardModal";
import TheDigitalSignature from "@/components/scorecard/TheDigitalSignature";

var VueScrollTo = require("vue-scrollto");
var scrollToOptions = {
  offset: -45,
  duration: 750,
};

export default {
  name: "TheMarker",
  components: {
    TheSinglePlayerKeyboard,
    TheScorecardMenu,
    TheScorecardModal,
    TheDigitalSignature,
  },
  data() {
    return {
      unlockQuestion: "",
      showMenu: false,
      useTeeOrder: true,
      blockScorecardHeader: false,
      showScorecardHeader: true,
      modalControl: {
        showDNFModal: false,
        showCheckScoresModal: false,
        showSubmitWarningModal: false,
        showSubmittedModal: false,
        showConnectionFailedModal: false,
        showUnverifiedScoresModal: false,
        showDuplicateScoresModal: false,
        showMarshallControlledModal: false,
      },
      dnfPlayer: {},
      showKeyboard: false,
      groupData: {},
      loading: true,
      clickedScore: {},
      keyBoardPreset: {
        hole: {
          id: "",
        },
        id: "",
        isCircleHit: false,
        isInsidePutt: false,
        isOutsidePutt: false,
        isOutOfBounds: false,
        score: 3,
      },
      keyBoardHole: {},
      keyBoardPlayer: {},
      keyBoardResult: {},
      unlockedHole: {},
      showAllStats: false,
      showTotalScore: true,
      showTotalPar: false,
    };
  },
  methods: {
    showHoleNameOrNumber(hole) {
      if (hole.name) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    formattedLastName(name) {
      if (name.length > 12) {
        return name.slice(0, 10 - name.length) + "...";
      } else return name;
    },
    loadPersistentSettings() {
      if (localStorage.getItem("persistentScorecardSettings")) {
        if (
          typeof JSON.parse(localStorage.getItem("persistentScorecardSettings"))
            .showTotalPar !== "undefined"
        ) {
          this.showTotalPar = JSON.parse(
            localStorage.getItem("persistentScorecardSettings")
          ).showTotalPar;
        }
        if (
          typeof JSON.parse(localStorage.getItem("persistentScorecardSettings"))
            .showTotalScore !== "undefined"
        ) {
          this.showTotalScore = JSON.parse(
            localStorage.getItem("persistentScorecardSettings")
          ).showTotalScore;
        }
        if (
          typeof JSON.parse(localStorage.getItem("persistentScorecardSettings"))
            .useTeeOrder !== "undefined"
        ) {
          this.useTeeOrder = JSON.parse(
            localStorage.getItem("persistentScorecardSettings")
          ).useTeeOrder;
        }
      }
    },
    unverifiedResult() {
      this.modalControl.showUnverifiedScoresModal = true;
      this.showKeyboard = false;
    },
    duplicateResult() {
      this.modalControl.showDuplicateScoresModal = true;
      this.showKeyboard = false;
    },
    alreadysigned() {
      this.$store.dispatch("showMessage", {
        show: true,
        message: "Results have already been Signed, contact TD",
        type: "error",
      });
      this.showKeyboard = false;
    },
    groupclosed() {
      this.$store.dispatch("showMessage", {
        show: true,
        message: "Group is closed",
        type: "error",
      });
      this.showKeyboard = false;
    },
    actionDNFPlayer() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation {
            UpdatePlayerDnf(playerId:"${this.dnfPlayer.id}",isDnf:true,asRole:USER){
              id
            }
          }
          `,
        },
      })
        .then(() => {
          this.modalControl.showDNFModal = false;
          this.dnfPlayer = {};
          this.loadData();
        })
        .catch(() => {
          this.$emit("connectionError");
        });
    },
    resignMarshall() {
      this.$store.dispatch("authCheck");

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation{
            RemoveGroupMarshall(groupId:"${this.$router.currentRoute.params.groupId}"){id}
          }
          `,
        },
      })
        .then(() => {
          this.$store.dispatch("checkForOpenGroups", true);
          this.$router.push("/");
        })
        .catch(() => {
          this.$emit("connectionError");
        });
    },
    lockScorecard() {
      this.$store.dispatch("authCheck");

      var asRole = "USER";

      if (this.isUserMarshall) {
        asRole = "GROUP_MARSHALL";
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation {
          	CloseGroup(groupId:"${this.$router.currentRoute.params.groupId}",asRole:${asRole}){
              id
            }
          }
          `,
        },
      })
        .then((result) => {
          if (result.data.data.CloseGroup != null) {
            this.groupData.status = "DONE";
            this.modalControl.showSubmittedModal = true;
          } else {
            this.modalControl.showSubmitWarningModal = false;
            this.modalControl.showUnverifiedScoresModal = true;
          }
        })
        .catch(() => {
          this.$emit("connectionError");
        });
    },
    connectionError() {
      this.modalControl.showConnectionFailedModal = true;
    },
    refreshScorecard() {
      this.showKeyboard = false;
      this.showMenu = false;
      this.modalControl.showDuplicateScoresModal = false;
      this.loadData();
    },
    showParPrefix(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else return par;
    },
    toggleTotals() {
      if (this.showTotalScore) {
        this.showTotalScore = false;
        this.showTotalPar = true;
      } else if (this.showTotalPar) {
        this.showTotalPar = false;
        this.hideTotals = true;
      } else {
        this.showTotalScore = true;
      }
    },
    getPlayerTotals(player) {
      return this.playerResults.filter(
        (playerResult) => playerResult.playerConnectionId == player.id
      )[0];
    },
    playersTeeOrder(player) {
      for (var i = 0; i < this.teeOrder.length; i++) {
        if (this.teeOrder[i].player.id == player.id) {
          return i + 1;
        }
      }
    },
    toggleCheckScoresModal() {
      if (this.modalControl.showCheckScoresModal) {
        this.modalControl.showCheckScoresModal = false;
      } else {
        this.toggleMenu(false);
        this.modalControl.showCheckScoresModal = true;
      }
    },
    toggleDNFModal(player, event) {
      if (this.groupData.status == "OPEN") {
        if (this.modalControl.showDNFModal) {
          this.modalControl.showDNFModal = false;
          this.dnfPlayer = {};
        } else {
          this.toggleMenu(false);
          this.dnfPlayer = player;
          this.modalControl.showDNFModal = true;
        }
      }
    },
    toggleMenu(bool) {
      if (this.showMenu || !bool) {
        this.showMenu = false;
      } else {
        this.modalControl.showDNFModal = false;
        this.dnfPlayer = {};
        this.showKeyboard = false;
        this.clickedScore = "";
        this.showMenu = true;
      }
    },
    moveScores(event) {
      if (event.direction == 4) {
        this.$router.push({ name: "events" });
      }
      if (event.direction == 2) {
        this.$router.push({ name: "verifier" });
      }
    },
    resetKeyboardOptions() {
      this.clickedScore = "";
      this.showKeyboard = false;
      this.keyBoardHole = {};
      this.keyBoardPlayer = {};
      this.keyBoardResult = {};
      this.resetKeyboardPreset();
    },
    updateResult(updatedResult) {
      for (var i = 0; i < this.groupData.results.length; i++) {
        if (this.groupData.results[i].id == updatedResult.UpdateResult.id) {
          this.groupData.results.splice(i, 1);
          this.groupData.results.push(updatedResult.UpdateResult);

          this.resetKeyboardOptions();
        }
      }
    },
    createResult(newResult) {
      if (
        this.groupData.results.filter(
          (result) => result.hole.id == this.currentHole.id
        ).length ==
        this.groupData.playerConnections.length - 1
      ) {
        this.groupData.results.push(newResult.CreateResult);
        this.resetKeyboardOptions();
      } else {
        this.groupData.results.push(newResult.CreateResult);
        this.resetKeyboardPreset();

        for (var i = 0; i < this.groupData.playerConnections.length; i++) {
          if (
            this.groupData.results.filter(
              (result) =>
                result.hole.id == this.currentHole.id &&
                result.playerConnection.id ==
                  this.groupData.playerConnections[i].id
            ).length == 0
          ) {
            this.clickedScore =
              this.currentHole.id + this.groupData.playerConnections[i].id;
            this.keyBoardPlayer = this.groupData.playerConnections[i];
            this.keyBoardResult = {};
            break;
          }
        }
      }
    },
    loadKeyboard(hole, player, event) {
      if (
        this.groupData.status == "OPEN" &&
        (!this.marshallUsed || this.isUserMarshall) &&
        (this.lockedHoles.filter((locked) => locked.id == hole.id).length ==
          0 ||
          this.unlockedHole.id == hole.id)
      ) {
        if (
          this.groupData.results.filter(
            (result) =>
              result.hole.id == hole.id &&
              result.playerConnection.id == player.id
          ).length > 0
        ) {
          VueScrollTo.scrollTo(event.srcElement, scrollToOptions);

          let result = this.groupData.results.filter(
            (result) =>
              result.hole.id == hole.id &&
              result.playerConnection.id == player.id
          )[0];
          this.clickedScore = hole.id + player.id;
          this.showKeyboard = true;
          this.keyBoardPreset.score = result.score;
          this.keyBoardPreset.isCircleHit = result.isCircleHit;
          this.keyBoardPreset.isInsidePutt = result.isInsidePutt;
          this.keyBoardPreset.isOutsidePutt = result.isOutsidePutt;
          this.keyBoardPreset.isOutOfBounds = result.isOutOfBounds;
          this.keyBoardHole = hole;
          this.keyBoardPlayer = player;
          this.keyBoardResult = result;

          if (this.unlockedHole.id != hole.id) {
            this.unlockedHole = {};
          }
        } else if (hole.id == this.currentHole.id) {
          VueScrollTo.scrollTo(event.srcElement, scrollToOptions);
          this.clickedScore = hole.id + player.id;
          this.showKeyboard = true;
          this.keyBoardHole = hole;
          this.keyBoardPlayer = player;
          this.keyBoardResult = {};
          this.unlockedHole = {};
          this.resetKeyboardPreset();
          this.unlockQuestion = "";
        } else {
          this.resetKeyboardOptions();
          this.unlockedHole = {};
          this.unlockQuestion = "";
        }
      } else if (this.marshallUsed && this.isUserMarshall == false) {
        this.modalControl.showMarshallControlledModal = true;
      } else {
        this.resetKeyboardOptions();
        if (this.unlockedHole.id != hole.id) {
          this.unlockedHole = {};

          if (this.unlockQuestion == hole.id) {
            this.unlockQuestion = "";
          } else {
            this.unlockQuestion = hole.id;
          }
        }
      }
    },
    resetKeyboardPreset() {
      this.keyBoardPreset = {
        hole: {
          id: "",
        },
        id: "",
        isCircleHit: false,
        isInsidePutt: false,
        isOutsidePutt: false,
        isOutOfBounds: false,
        score: 0,
      };
    },
    toggleLock(hole) {
      if (this.groupData.status == "OPEN") {
        if (this.unlockedHole.id == hole.id) {
          this.resetKeyboardOptions();
          this.unlockedHole = {};
        } else if (
          this.lockedHoles.filter((locked) => locked.id == hole.id).length > 0
        ) {
          this.unlockedHole = hole;
          this.unlockQuestion = "";
        }
      }
    },
    isUnlocked(hole) {
      if (this.unlockedHole.id == hole.id) return true;
      else return false;
    },
    lockedHole(hole) {
      if (this.lockedHoles.filter((locked) => locked.id == hole.id).length == 1)
        return true;
      else return false;
    },
    resultParColor(holePar, result) {
      if (result != 0) {
        if (result == 1) {
          return (
            "background-color: " + this.$store.state.colors.parColors["ace"].hex
          );
        } else if (result - holePar.par <= -3) {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["albatross"].hex
          );
        } else if (result - holePar.par == -2) {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["eagle"].hex
          );
        } else if (result - holePar.par == -1) {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["birdie"].hex
          );
        } else if (result - holePar.par == 0) {
          return (
            "background-color: " + this.$store.state.colors.parColors["par"].hex
          );
        } else if (result - holePar.par == 1) {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["bogey"].hex
          );
        } else if (result - holePar.par == 2) {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["doubleBogey"].hex
          );
        } else {
          return (
            "background-color: " +
            this.$store.state.colors.parColors["tripleBogey"].hex
          );
        }
      }
    },
    resultToDisplay(player, hole) {
      let result = this.groupData.results.filter(
        (result) =>
          result.playerConnection.id == player.id && hole.id == result.hole.id
      );

      if (result.length == 1) {
        return result[0];
      } else
        return {
          hole: {},
          id: "",
          isCircleHit: false,
          isInsidePutt: false,
          isOutsidePutt: false,
          isOutOfBounds: false,
          score: "",
        };
    },
    loadData() {
      this.$store.dispatch("authCheck");

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            group(groupId:"${this.$router.currentRoute.params.groupId}"){
              status
              startHole{
                id
                number
              }
              marshall{
                id
                firstName
                lastName
              }
              pool{
                status
                id
                round{
                  event{
                    id
                    isResultSigningEnabled
                    isVerifyMode
                  }
                }
                layoutVersion{
                  holes{
                    id
                    number
                    par
                    name
                  }
                }
              }
              playerConnections{
                id
                hasSignedResult
                player{
                  id
                  dnf{
                    isDnf
                  }
                  dns{
                    isDns
                  }
                  user{
                    id
                    firstName
                    lastName
                  }
                  division{
                    type
                    name
                  }
                }
              }
              results{
                id
                score
                isCircleHit
                isOutsidePutt
                isInsidePutt
                isOutOfBounds
                hole{
                  id
                  par
                }
                playerConnection{
                  id
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          var tempGroupData = result.data.data.group;
          for (var p = 0; p < tempGroupData.playerConnections.length; p++) {
            if (
              tempGroupData.playerConnections[p].player.dnf.isDnf ||
              tempGroupData.playerConnections[p].player.dns.isDns
            ) {
              tempGroupData.results = tempGroupData.results.filter(
                (playerresult) =>
                  playerresult.playerConnection.id !=
                  tempGroupData.playerConnections[p].id
              );
              tempGroupData.playerConnections.splice(p, 1);
            }
          }
          this.groupData = tempGroupData;
          this.loading = false;

          if (result.data.data.group.pool.status == "COMPLETED") {
            this.$router.push({ name: "scorecard-picker" });
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    marshallUsed() {
      if (
        this.groupData.marshall &&
        Object.keys(this.groupData.marshall).length !== 0
      )
        return true;
      return false;
    },
    isUserMarshall() {
      if (this.groupData.marshall == null) {
        return false;
      } else if (
        this.groupData.marshall.id == this.$store.state.user.sessionInfo.userId
      ) {
        return true;
      } else return false;
    },
    userAuthorized() {
      return true;
    },
    showDigitalSignature() {
      if (
        this.scoreCardCompleted &&
        this.groupData.pool.round.event.isResultSigningEnabled
      ) {
        return true;
      } else return false;
    },
    showAModal() {
      for (var property in this.modalControl) {
        if (this.modalControl[property] == true) return true;
      }
      return false;
    },
    scoreCardCompleted() {
      if (!this.loading) {
        if (
          this.groupData.results.length ==
          this.groupData.playerConnections.length *
            this.groupData.pool.layoutVersion.holes.length
        )
          return true;
        else return false;
      } else {
        return false;
      }
    },
    playerResults() {
      var allResults = [];

      for (var i = 0; i < this.groupData.playerConnections.length; i++) {
        var playerResults = this.groupData.results.filter(
          (result) =>
            result.playerConnection.id == this.groupData.playerConnections[i].id
        );

        var playerResult = {
          playerTotal: 0,
          parSoFar: 0,
          playerPar: 0,
          playerConnectionId: this.groupData.playerConnections[i].id,
        };

        for (var j = 0; j < playerResults.length; j++) {
          playerResult.playerTotal += playerResults[j].score;
          playerResult.parSoFar += playerResults[j].hole.par;
        }

        playerResult.playerPar =
          playerResult.playerTotal - playerResult.parSoFar;

        allResults.push(playerResult);
      }

      return allResults;
    },
    showTeeOrder() {
      if (
        this.scoreCardCompleted == false &&
        (this.showKeyboard ||
          this.groupData.results.filter(
            (result) => result.hole.id == this.currentHole.id
          ).length != 0)
      ) {
        return false;
      } else {
        if (this.useTeeOrder == false) return false;
        else return true;
      }
    },
    teeOrder() {
      let allScores = [];

      let tempLocked = JSON.parse(JSON.stringify(this.lockedHoles));
      tempLocked.reverse();

      for (var i = 0; i < this.groupData.playerConnections.length; i++) {
        var playerScores = {
          player: this.groupData.playerConnections[i],
          results: [],
        };
        let player = this.groupData.playerConnections[i];

        for (var j = 0; j < tempLocked.length; j++) {
          playerScores.results.push(
            this.groupData.results.filter(
              (result) =>
                result.hole.id == tempLocked[j].id &&
                result.playerConnection.id == player.id
            )[0]
          );
        }

        allScores.push(playerScores);
      }

      allScores.sort(function (a, b) {
        for (var i = 0; i < a.results.length; i++) {
          if (a.results[i].score < b.results[i].score) {
            return -1;
          } else if (a.results[i].score > b.results[i].score) {
            return 1;
          }
        }
        return 0;
      });

      return allScores;
    },
    lockedHoles() {
      let lockedHoles = [];

      for (var i = 0; i < this.holeOrder.length; i++) {
        let holeResults = this.groupData.results.filter(
          (result) => result.hole.id == this.holeOrder[i].id
        );

        if (holeResults.length == this.groupData.playerConnections.length) {
          lockedHoles.push(this.holeOrder[i]);
        }
      }

      return lockedHoles;
    },
    currentHole() {
      for (var i = 0; i < this.holeOrder.length; i++) {
        let holeResults = this.groupData.results.filter(
          (result) => result.hole.id == this.holeOrder[i].id
        );

        if (holeResults.length != this.groupData.playerConnections.length) {
          return this.holeOrder[i];
        }
      }

      return 0;
    },
    holeOrder() {
      let holeOrder = [];
      let beforeStartHole = [];
      let afterStartHole = [];
      let startHole = {};

      for (var i = 0; i < this.groupData.pool.layoutVersion.holes.length; i++) {
        if (
          this.groupData.pool.layoutVersion.holes[i].number ==
          this.groupData.startHole.number
        ) {
          startHole = this.groupData.pool.layoutVersion.holes[i];
        } else if (
          this.groupData.pool.layoutVersion.holes[i].number <
          this.groupData.startHole.number
        ) {
          beforeStartHole.push(this.groupData.pool.layoutVersion.holes[i]);
        } else if (
          this.groupData.pool.layoutVersion.holes[i].number >
          this.groupData.startHole.number
        ) {
          afterStartHole.push(this.groupData.pool.layoutVersion.holes[i]);
        }
      }

      return holeOrder.concat(startHole, afterStartHole, beforeStartHole);
    },
  },
  watch: {
    showTotalPar: function (newValue) {
      if (localStorage.getItem("persistentScorecardSettings")) {
        let persistentScorecardSettings = JSON.parse(
          localStorage.getItem("persistentScorecardSettings")
        );

        persistentScorecardSettings.showTotalPar = newValue;
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      } else {
        let persistentScorecardSettings = { showTotalPar: newValue };
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      }
    },
    showTotalScore: function (newValue) {
      if (localStorage.getItem("persistentScorecardSettings")) {
        let persistentScorecardSettings = JSON.parse(
          localStorage.getItem("persistentScorecardSettings")
        );

        persistentScorecardSettings.showTotalScore = newValue;
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      } else {
        let persistentScorecardSettings = { showTotalScore: newValue };
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      }
    },
    useTeeOrder: function (newValue) {
      if (localStorage.getItem("persistentScorecardSettings")) {
        let persistentScorecardSettings = JSON.parse(
          localStorage.getItem("persistentScorecardSettings")
        );

        persistentScorecardSettings.useTeeOrder = newValue;
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      } else {
        let persistentScorecardSettings = { useTeeOrder: newValue };
        localStorage.setItem(
          "persistentScorecardSettings",
          JSON.stringify(persistentScorecardSettings)
        );
      }
    },
    scoreCardCompleted: function () {
      if (this.scoreCardCompleted && this.groupData.status == "OPEN") {
        this.modalControl.showCheckScoresModal = true;
        this.blockScorecardHeader = true;
      } else if (this.scoreCardCompleted) {
        this.blockScorecardHeader = true;
      }
    },
    showAModal: function () {
      this.showKeyboard = false;
      this.clickedScore = "";
    },
  },
  mounted() {
    // Remove this, only here for the devservers preseved state not triggering the beforemount method
    this.$store.dispatch("setMainNavState", false);
    this.$store.dispatch("setFooterState", false);
  },
  beforeMount() {
    this.loadData();

    this.loadPersistentSettings();

    this.$store.dispatch("setMainNavState", false);
    this.$store.dispatch("setFooterState", false);
  },
  beforeDestroy() {
    // this.$store.dispatch('setMainNavState', true);
  },
};
</script>
<style lang="scss" scoped>
.unlock-question {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  width: calc(100vw - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  background-color: $watermelon;
}
.scorecard-container {
  position: relative;
  min-height: 100vh;
}

.dim {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.locked {
    top: 44px;
  }
}

.slide-from-right-enter {
  right: -300px;
}

.slide-from-right-enter-to {
  right: 0;
}
.slide-from-right-leave-active {
  right: -300px;
}

.slide-in-left-enter {
  left: -100vw;
  top: -1px;
}

.slide-in-left-enter-to {
  top: -1px;
  left: 0px;
  width: 100vw;
  opacity: 1;
  top: -1px;
}
.slide-in-left-leave-active {
  left: -100vw;
  top: -1px;
}

.slide-up-enter-active {
  transition: all 0.3s ease;
}
.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter,
.slide-up-leave-to {
  max-height: 0;
  opacity: 0;
}

.bottom-spacing {
  margin-bottom: 300px;
}
.scorecard-header {
  background-color: $midnight;
  z-index: 4;
  display: flex;
  height: 44px;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .left-column {
    width: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .division-spacer {
      height: 4px;
      width: 100%;
      z-index: 4;
    }
    .menu-container {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
  .players-container {
    width: calc(100% - 45px);
    display: flex;
    flex-direction: row;
    text-transform: uppercase;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */

    .player-column {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      border-right: 1px solid $elephant;

      &:last-child {
        border-right: 0px solid $elephant;
      }

      .division {
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
      }

      .player {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: white;

        span:first-child {
          @include Gilroy-Bold;
        }
        span:last-child {
          @include Gilroy-Regular;
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}
.scorecard-body {
  z-index: 0;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  margin-right: 10px;
  justify-content: flex-start;
  position: relative;

  &.margin-top {
    margin-top: 45px;
  }
  .left-column {
    width: 45px;

    .hole-number {
      height: 45px;
      background-color: $elephant;
      @include Gilroy-Bold;
      line-height: normal;
      color: white;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      border-bottom: 1px solid white;
      border-right: 1px solid white;

      &.small {
        font-size: 16px;
      }

      .locked {
        position: absolute;
        right: -8px;
        top: 14px;
        z-index: 3;
      }

      .indicator {
        position: absolute;
        left: 0;
        height: 12px;
        top: 15px;
      }
    }
  }

  .players-container {
    width: calc(100% - 45px);
    display: flex;
    height: 45px;
    position: relative;

    .player-column {
      .player-result {
        position: relative;
        border-right: 1px solid white;
        border-bottom: 1px solid white;
        height: 45px;
        line-height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include Gilroy-Bold;
        font-size: 24px;
        color: $midnight;
        transition: all ease 0.3s;
        background-color: $mist;

        &.clicked {
          background-color: lighten($elephant, 20%) !important;
        }

        .teeorder {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          margin: 0;
          width: 22px;
          height: 22px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: $elephant;
          color: white;
          @include Gilroy-Medium;
        }

        .circlehit {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
        }
        .inside-putt {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 18px;
          height: 18px;
        }
        .outside-putt {
          position: absolute;
          top: 0;
          right: 0;
          width: 18px;
          height: 18px;
        }
        .ob {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 18px;
          height: 18px;
        }
      }

      &:last-child {
        .player-result {
          border-right: 0px solid black;
        }
      }
    }
  }
}

.scorecard-footer {
  display: flex;
  background-color: $midnight;
  color: white;
  border-bottom: 1px solid white;

  .toggle-area {
    min-width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid white;
    img {
      width: 24px;
    }
  }

  .player-result {
    border-right: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    @include Gilroy-Bold;

    &:last-of-type {
      border-right: 0px solid white;
    }
  }
}

.submit-area {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  @include Gilroy-Bold;
  height: 150px;
  background-color: $midnight;
  border-top: 1px solid white;
  font-size: 20px;

  div {
    display: flex;
    flex-direction: column;
    img {
      height: 32px;
      margin-bottom: 10px;
    }
  }
}
#nav-icon3 {
  width: 24px;
  height: 20px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: white;
  border-radius: 3px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 8px;
}

#nav-icon3 span:nth-child(4) {
  top: 16px;
}

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
@media (max-width: 340px) {
  .player {
    span {
      &.first-name {
        font-size: 11px !important;
      }

      &.last-name {
        font-size: 9px !important;
        line-height: 10px;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .scorecard-container {
    max-width: 475px;
    margin: 48px auto 0 auto;
    min-height: unset;
  }
  .scorecard-header {
    border-left: 6px solid white;
    border-right: 6px solid white;
    &.fixed {
      width: 1200px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .scorecard-body {
    border-left: 6px solid white;
    border-right: 6px solid white;

    &.margin-top {
      margin-top: 44px;
    }
  }
  .scorecard-footer {
    border-left: 6px solid white;
    border-right: 6px solid white;
    border-bottom: 6px solid white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .submit-area {
    border-top: 0;
  }
  .signature-container {
    border-left: 6px solid white;
    border-right: 6px solid white;
    border-top: 6px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .mobile-modal {
    width: 100%;
    min-height: 100%;

    &.content-container {
      min-height: unset;
    }
  }
}
</style>
