<template>
  <div class="mobile-modal">
    <transition name="fade" mode="in-out">
      <div class="birdie-bg content-container" v-if="modals.showSubmittedModal">
        <div class="header">
          <img class="icon" src="@/assets/img/thumbs-up-dark.svg" />
          So simple
        </div>
        <div class="body">
          We just received your scorecard. Isn't it lovely when things just work
        </div>
        <div class="buttons">
          <img
            class="close-icon"
            src="@/assets/img/close-dark.svg"
            @click="closeSubmitted()"
          />
        </div>
      </div>
    </transition>
    <transition name="fade" mode="in-out">
      <div
        class="sky-bg content-container"
        v-if="modals.showSubmitWarningModal"
      >
        <div class="header text-only">You're about to submit the scores</div>
        <div class="body">
          You will not be able to make any further corrections.
        </div>

        <div class="buttons">
          <button
            type="button"
            name="button"
            class="dark hollow"
            @click="$parent.modalControl.showSubmitWarningModal = false"
          >
            Cancel
          </button>
          <button
            type="button"
            name="button"
            class="dark solid"
            @click="$emit('submitScores')"
          >
            Submit scores
          </button>
        </div>
      </div>
    </transition>

    <div class="doublebogey-bg content-container" v-if="modals.showDNFModal">
      <div class="header">
        <img class="icon" src="@/assets/img/dnf-dark.svg" />
        You are about to DNF {{ player.user.firstName }}!
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="midnight tall full"
          @click="$parent.modalControl.showDNFModal = false"
        >
          Cancel
        </button>
        <button
          type="button"
          name="button"
          class="midnight tall hollow full"
          @click="$emit('dnfPlayer')"
        >
          DNF {{ player.user.firstName }}
        </button>
      </div>
    </div>

    <div
      class="doublebogey-bg content-container"
      v-if="modals.showUnverifiedScoresModal"
    >
      <div class="header">
        <img class="icon" src="@/assets/img/caution-dark.svg" />
        Scores not verified
      </div>
      <div class="body">
        The scores from the last hole have not yet been verified. Check with the
        verifier.
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="dark solid doublebogey"
          @click="$parent.modalControl.showUnverifiedScoresModal = false"
        >
          Okay
        </button>
      </div>
    </div>

    <div
      class="doublebogey-bg content-container"
      v-if="modals.showDuplicateScoresModal"
    >
      <div class="header">
        <img class="icon" src="@/assets/img/caution-dark.svg" />
        Careful
      </div>
      <div class="body">
        It looks like a player in your group has also entered scores on this
        hole.
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="dark solid doublebogey"
          @click="$emit('refresh')"
        >
          Refresh the scorecard
        </button>
      </div>
    </div>

    <div
      class="doublebogey-bg content-container"
      v-if="modals.showConnectionFailedModal"
    >
      <div class="header">
        <img class="icon" src="@/assets/img/caution-dark.svg" />
        What a bummer
      </div>
      <div class="body">
        Check you have a signal or ask a player from your group to log in on
        their device and try. If that doesn't excite you then just try again ...
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="dark solid doublebogey"
          @click="$parent.modalControl.showConnectionFailedModal = false"
        >
          Try again
        </button>
      </div>
    </div>

    <div class="mouse-bg content-container" v-if="modals.showCheckScoresModal">
      <div class="header text-only">Double check the scores</div>

      <div class="body">
        Take a final look at the scores with your group and make any necessary
        corrections before tapping the submit button.
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="midnight full tall"
          @click="$parent.modalControl.showCheckScoresModal = false"
        >
          Okay, I'm on it
        </button>
      </div>
    </div>

    <div
      class="mouse-bg content-container"
      v-if="modals.showMarshallControlledModal"
    >
      <div class="header text-only">Marshall</div>

      <div class="body">
        The scorecard is controlled by a scoring Marshall, you cannot to edit
        the scores yourself!
      </div>

      <div class="buttons">
        <button
          type="button"
          name="button"
          class="dark solid fluid"
          @click="$parent.modalControl.showMarshallControlledModal = false"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheScorecardModal",
  data() {
    return {
      adminView: false,
    };
  },
  props: ["player", "modals"],
  methods: {
    closeSubmitted() {
      this.$parent.modalControl.showSubmittedModal = false;
      this.$parent.modalControl.showSubmitWarningModal = false;
    },
    closeMenu(event) {
      if (event.direction == 2) {
        this.$emit("closeMenu");
      }
    },
    clickEvent(event) {
      if (event.action == "close") {
        this.$emit("closeModal");
      }
      if (event.action == "execute") {
        this.$emit("execute");
      }
    },
  },
  beforeMount() {
    if (this.$router.currentRoute.path.includes("/manage/")) {
      this.adminView = true;
    }
  },
  beforeCreate() {
    window.scrollTo(0, top);
    this.$store.dispatch("toggleScorecardModal", true);
  },
  destroyed() {
    this.$store.dispatch("toggleScorecardModal", false);
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 280px;
  margin: 10px 0px;
  height: 64px !important;
  font-size: 20px !important;
}
.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
}
.mobile-modal {
  position: absolute;
  touch-action: none;
  top: 0px;
  left: 0;
  z-index: 9;
  width: 100vw;
  display: flex;
  flex-direction: column;
  transition: all ease 0.5s;
  max-height: 100vh;
  min-height: calc(100vh - 44px);
  justify-content: flex-start;

  .content-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 60px 0px;
  }
  .doublebogey-bg {
    box-shadow: inset 0px 3px 10px darken($doublebogey, 5%);
    background-color: $doublebogey;
    color: $midnight;
  }

  .par-bg {
    box-shadow: inset 0px 3px 10px darken($par, 5%);
    background-color: $par;
    color: $midnight;
  }

  .mouse-bg {
    background-color: $mouse;
  }

  .birdie-bg {
    background-color: $birdie;
  }
  .sky-bg {
    background-color: $albatross;

    button {
      &.hollow.dark {
        border: 1px solid $midnight;
        background-color: transparent;
        color: $midnight;
        @include Gilroy-Bold;
      }

      &.solid.dark {
        border: 1px solid $midnight;
        background-color: $midnight;
        color: $albatross;
      }
    }
  }

  .buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 0 15px;

    .close-icon {
      height: 36px;
    }
  }
}

.icon {
  height: 80px;
  margin-bottom: 30px;
}

.header {
  @include Gilroy-Bold;
  font-size: 36px;
  text-align: center;
  padding: 0px 30px;
  display: flex;
  flex-direction: column;
}

.body {
  @include Gilroy-Medium;
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 30px;
  text-align: center;
}

button {
  &.doublecheck {
  }
}

@media (max-height: 500px) and (orientation: portrait) {
  .text-only {
    margin-top: 30px !important;
  }

  .body {
    @include Gilroy-Medium;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 15px;
    text-align: center;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
</style>
